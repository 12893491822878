<template>
  <div class="container">
    <div class="vip-module">
      <!-- <div class="vip-bg">
        <div class="banner">
          <el-image
            :src="ossUrl + '/images/vip-img/vip-adorn (4).png'"
            fit="scale-down"
            :preview-src-list="[ossUrl + '/images/vip-img/vip-adorn (4).png']"
          ></el-image>
        </div>
        <div class="rest-bg">
          <el-image
            :src="ossUrl + '/images/vip-img/vip-adorn (6).png'"
            fit="scale-down"
            :preview-src-list="[ossUrl + '/images/vip-img/vip-adorn (6).png']"
          ></el-image>
        </div>
      </div> -->
      <div class="vip-banner">
        <el-image :src="ossUrl + '/images/vip-img/vip-adorn (4).png'" fit="scale-down"></el-image>
        <div class="title">
          <!-- <p class="title-text">佰团乐VIP会员</p> -->
          <p class="descriptions">已有{{vip_sum_number}}人使用了佰团乐的会员权益</p>
        </div>
      </div>
      <div class="vip-body">
        <div class="vip-bg" :style="{backgroundImage: 'url(\''+ ossUrl +'/images/vip-img/vip-adorn (6).png\')'}"></div>
        <div class="body-main">
          <div class="vip-palce">
            <div class="vp-title">
              <span class="title-text">会员等级任你挑选</span>
            </div>
            <div class="vp-rule">
              <span class="rule-text" @click.stop.prevent="activityRulesFun">
                会员权益细则
                <i class="iconfont icon-arrow-right-1-icon"></i>
              </span>
            </div>
            <ul class="vip-list">
              <li class="vl-item" v-for="(item,index) in vip_data" :key="index">
                <div class="vl-sign">
                  <el-image class="fake-img" :src="ossUrl + '/images/vip-img/vip-v1-sign (' + (index + 1) + ').png'"
                    fit="scale-down">
                  </el-image>
                  <div class="vl-recommend" v-if="index == 1">推荐：90%的人购买</div>
                </div>
                <div class="vl-price">
                  <div class="price-main">
                    <span class="price-unit">￥</span>
                    <span class="price-text"
                      v-if="item.activity_enable == 1">{{item.activity_data.activity_price}}</span>
                    <span class="price-text" v-else>{{item.price}}</span>
                    <span class="price-unit">
                      <span>/年</span>
                      <!-- <span>.00</span>
                      <span class="price-raw">原价：{{item.price}}.00</span> -->
                      <span class="price-raw" v-if="item.activity_enable == 1">原价：{{item.price}}</span>
                    </span>
                  </div>
                  <div class="price-rest">
                    <!-- <span class="price-placeholder">原价：{{item.price}}.00</span> -->
                    <span class="price-placeholder" v-if="item.activity_enable == 1">原价：{{item.price}}</span>
                  </div>
                </div>
                <div class="vl-botton-module">
                  <div class="vbm-place">
                    <div class="vl-botton" :class="'number'+index">
                      <el-button class="fake-btn" round @click.stop="clickPayVipBtnFun(item.id)">立即开通</el-button>
                    </div>
                    <div class="vbm-line"></div>
                    <div class="vbm-text">{{item.advantage}}</div>
                  </div>
                </div>
                <div class="vl-privilege">
                  <div class="block-title">
                    <div class="title-line"></div>
                    <div class="title-text">VIP尊享权益</div>
                    <div class="title-line"></div>
                  </div>
                  <ul class="vpr-list">
                    <li class="vpr-item" :class="item.patent.length == 4 ? 'a' : ''"
                      v-for="(citem,index) in item.patent" :key="index">
                      <div class="icon">
                        <el-image class="fake-img" :src="citem.images" fit="scale-down">
                        </el-image>
                      </div>
                      <div class="name">{{citem.title}}</div>
                      <div class="descriptions">{{citem.describe}}</div>
                    </li>
                  </ul>
                </div>
                <div class="vl-privilege-two">
                  <div class="block-title">
                    <div class="title-line"></div>
                    <div class="title-text">VIP赠送权益</div>
                    <div class="title-line"></div>
                  </div>
                  <ul class="vpt-list">
                    <li class="vpt-item" v-for="(citem,index) in item.bestow_patent" :key="index">
                      <div class="vpt-icon">
                        <el-image class="fake-img" :src="citem.images" fit="scale-down">
                        </el-image>
                      </div>
                      <div class="vpt-info">
                        <span class="vpt-title">
                          <span class="title-key">{{citem.title}}</span>
                          <span class="title-val">{{citem.notice}}</span>
                        </span>
                        <span class="vpt-descriptions">{{citem.describe}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 支付弹窗 -->
    <!-- <my-pay-dialog
      :visible="pay_config.visible"
      :payee="pay_config.payee"
      :price="pay_config.price"
      :qr-code="pay_config.qr_code"
      type="2"
      @on-change="payResultChangeFun"
    /> -->

    <!-- 支付弹窗 -->
    <my-pay-dialog type="2" :visible="pay_config.visible" :payee="pay_config.payee" :price="pay_config.price"
      :qr-code="pay_config.qr_code" :pay-mode="pay_config.select.pay_mode" @on-change="payResultChangeFun" />

    <!-- 选址支付方式 -->
    <select-pay-method :visible="pay_config.select.visible" @on-change="choosePayResultChangeFun" />

  </div>
</template>

<script>
  import {
    deepClone, // 深拷贝
  } from '@/utils/utils_fun.js';
  export default {
    // 允许组件模板递归地调用自身
    name: 'vip-one',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        ossUrl: this.GLOBAL.ossUrl,
        vip_sum_number: 999,
        pay_config: { // 支付配置
          select: {
            metadata: {},
            visible: false,
            value: '',
            pay_mode: '',
            register: {}, // 寄存器
          },
          visible: false,
          pay_timer: null,
          payee: '',
          price: '',
          qr_code: '',
        },
        not_vip_pamar: {}, // 非 vip 数据参数
        vip_data: [], // vip 数据
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取vip购买人数
        this.getVipSumNumberFun();

        // 获取vip数据
        this.getVipDataFun().then(() => {});
      },
      /**
       * 获取vip购买人数
       */
      getVipSumNumberFun() {
        this.myRequest({
          url: '/api/user/vipbuynumber',
        }).then(res => {
          console.log('获取vip购买人数 res == ', res);
          let resData = res.data.data
          this.vip_sum_number = resData.number;
        })
      },
      /**
       * 获取vip数据
       */
      getVipDataFun() {
        return new Promise((resolve, reject) => {
          this.myRequest({
            url: '/api/user/viptemplate',
          }).then(res => {
            console.log('vip数据 res == ', res);
            let resData = res.data.data;

            let result = resData.map(item => {
              // console.log('item == ',item);
              // item.price = item.price.replace('.00', '');
              let price_split = item.price.split(".");
              item.price_disassemble = {
                integer: price_split[0], // 整数
                decimals: price_split[1] ? price_split[1] : '.00', // 小数
              }
              if (item.activity_enable == 1) {
                // item.activity_data.activity_price = item.activity_data.activity_price.replace('.00', '');
                let activity_enable_split = item.price.split(".");
                item.activity_data.price_disassemble = {
                  integer: activity_enable_split[0], // 整数
                  decimals: activity_enable_split[1] ? activity_enable_split[1] : '.00', // 小数
                }
              }
              return item;
            })

            this.vip_data = result;
            console.log('vip数据  this.vip_data == ', this.vip_data);
            resolve();
          }).catch(err => {
            console.log('vip数据 err == ', err);
          })
        })
      },
      /**
       * 设置寄存器数据
       */
      setRegisterDataFun(opt = {}) {
        this.pay_config.select.register = Object.assign(this.pay_config.select.register, opt);
      },
      /**
       * 点击购买vip按钮
       * 流程： 点击购买vip按钮 -> 获取vip套餐id -> 获取支付方式 -> 获取vip套餐订单 -> 发起购买vip请求
       * @param {String,Number} id
       */
      clickPayVipBtnFun(id) {
        console.log('点击购买vip按钮 id == ', id)
        // 自定义清除定时器
        this._clearIntervalFun();

        // 设置寄存器数据
        this.setRegisterDataFun({
          'template_id': id
        })

        // 选择支付-开关
        this.choosePayTaggleFun(1);
      },
      /**
       *  发送获取vip套餐订单请求
       */
      sendGetVipOrderRequestFun() {
        const _this = this;
        console.log('发送获取vip套餐订单请求 this.pay_config == ', this.pay_config)
        let {
          metadata,
          register
        } = this.pay_config.select;
        let {
          id: payment_id,
          name,
          payment
        } = metadata;
        let {
          template_id
        } = register;

        // 获取vip套餐订单
        this.getVipPackageOrderFun(template_id).then((res) => {
          let {
            order_id,
            price
          } = res.data.data;
          this.pay_config.price = price;
          // 发起购买vip请求
          this.sendBuyVipRequestFun(order_id, payment_id).then((res) => {
            let resData = res.data.data;
            // this.pay_config.qr_code = pay;
            // this.pay_config.visible = true;

            if (res.data.code != 0) {
              this.myMessage({
                message: res.data.msg,
              });
              return;
            }

            // 如果是钱包支付
            if (payment == 'WalletPay') {
              // 支付结果回调
              _this.payResultChangeFun({
                visible: false,
                status: 1, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
                message: '支付成功',
                data: '',
              });
            } else {

              // 循环请求
              this.myLoopRequest({
                id: resData.order_id,
                type: 'vip_order'
              }, function (res) {
                console.log('myLoopRequest == ', res)
                let {
                  timer,
                  data
                } = res;

                // 获取定时器
                if (!_this.pay_config.pay_timer) {
                  _this.pay_config.pay_timer = timer;
                  // 打开支付弹窗
                  _this.openPayWindowFun({
                    payee: resData.order_payee,
                    qr_code: resData.pay
                  })
                }
                console.log('_this.pay_config.pay_timer == ', _this.pay_config.pay_timer)

                console.log('data.pay_status == ', data.pay_status)
                // 是否已支付
                if (data.pay_status == 1) {
                  console.log('是否已支付 == ', data.pay_status)
                  // clearInterval(_this.pay_config.pay_timer);
                  // 支付结果回调
                  _this.payResultChangeFun({
                    visible: false,
                    status: 1, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
                    message: '支付成功',
                    data: '',
                  });
                } else if (status == 3) { // 超时
                  // 支付结果回调
                  _this.payResultChangeFun(res);
                }
              })

            }

          })
        })
      },
      /**
       * 获取vip套餐订单
       * @param {String,Number} id
       */
      getVipPackageOrderFun(id) {
        return new Promise((resolve, reject) => {
          this.myRequest({
            url: '/api/user/vipbuy',
            data: {
              'template_id': id,
            }
          }).then(res => {
            console.log('获取vip套餐订单 res == ', res);
            resolve(res);
          }).catch(err => {
            console.log('获取vip套餐订单 err == ', err);
          })
        })
      },
      /**
       * 发起购买vip请求
       */
      sendBuyVipRequestFun(order_id, payment_id) {
        return new Promise((resolve, reject) => {
          this.myRequest({
            url: '/api/user/vippay',
            data: {
              order_id: order_id,
              payment_id: payment_id,
            }
          }).then(res => {
            console.log('发起购买vip请求 res == ', res);
            resolve(res);
          }).catch(err => {
            console.log('发起购买vip请求 err == ', err);
          })
        })
      },
      /**
       * 选择支付-开关
       */
      choosePayTaggleFun(flag) {
        let result;
        switch (+flag) {
          case 1:
            result = true;
            break;
          case 2:
            result = false;
            break;
          default:
            result = !this.pay_config.select.visible;
            break;
        }
        this.pay_config.select.visible = result;
      },
      /**
       * 选择支付-结果回调
       */
      choosePayResultChangeFun(e) {
        console.log('选择支付结果回调 e == ', e)
        const _this = this;
        let {
          visible,
          status,
          message,
          data
        } = e;
        this.pay_config.select.visible = visible;
        // 是否选择支付成功
        if (status == 1) {
          let {
            id,
            name,
            payment,
          } = data
          this.pay_config.select.metadata = deepClone(data);
          this.pay_config.select.value = id;
          this.pay_config.select.pay_mode = name;
          console.log('打开支付弹窗 this.pay_config == ', this.pay_config)

          // 发送获取vip套餐订单请求
          this.sendGetVipOrderRequestFun();
        }
      },
      /**
       * 打开支付弹窗
       */
      openPayWindowFun(opt) {
        console.log('打开支付弹窗 opt == ', opt)
        this.pay_config.payee = opt.payee;
        this.pay_config.qr_code = opt.qr_code;
        this.pay_config.visible = true;
      },
      /**
       * 支付结果回调
       */
      payResultChangeFun(e) {
        console.log('支付结果回调 e == ', e)
        let {
          visible,
          status,
          message,
          data
        } = e;
        this.pay_config.visible = visible;
        // 自定义清除定时器
        this._clearIntervalFun();
        // 是否支付成功
        if (status == 1) {
          // 发起录入后
          this.sendEnteringAfterFun();
          // this.myMessage({
          //   type: 'success',
          //   message: message,
          // });
        } else {
          this.myMessage({
            message: message,
          });
        }
      },
      /**
       * 自定义清除定时器
       */
      _clearIntervalFun() {
        if (this.pay_config.pay_timer) {
          clearInterval(this.pay_config.pay_timer);
          this.pay_config.pay_timer = null;
        }
      },
      /**
       * 发起录入后
       */
      sendEnteringAfterFun() {
        // 跳转参数
        let skip_qurey = {
          status: '1',
          status_msg: '会员开通成功！',
          descriptions: '',
          text_list: ['可在佰团乐小程序查看您的会员权益！'], // 文本
        };

        // 跳转路由
        this.myRouterPush({
          path: '/succeed',
          query: {
            data: encodeURIComponent(JSON.stringify(skip_qurey)),
          }
        });
      },
      /**
       * 查看活动规则
       */
      activityRulesFun: function () {
        let {
          patent_explain
        } = this.vip_data[0];

        let itemStr = '';

        console.log('查看活动规则 patent_explain == ', patent_explain)

        patent_explain.forEach(item => {
          itemStr += `<li style="margin-top: 8px; font-size: 14px; color: #2C2B2A;">${item}</li>`
        });

        let html = `<div>
          <ul >${itemStr}</ul>
         </div>`;

        let result = {
          name: '佰团乐会员权益细则',
          html: html,
        };
        this.$alert(result.html, result.name, {
          dangerouslyUseHTMLString: true,
          customClass: 'vip-rule-alert-class'
        }).catch((err) => {

        });
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前
      // 自定义清除定时器
      this._clearIntervalFun();
    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
  }

  .vip-module {
    position: relative;
    overflow: hidden;

    .vip-bg {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 0;
    }

    .vip-banner {
      position: relative;
      font-size: 0;
    }

    .title {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      // margin: 50px 0;
      text-align: center;
      font-size: 36px;
      color: #fff;

      .descriptions {
        font-size: 18px;
        margin-top: 10px;
      }
    }

    .vip-body {
      position: relative;

      .vip-bg {
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .body-main {
        box-sizing: border-box;
        padding: 0 $theme-widht-whiteedge;
        // width: $theme-view-widht;
        max-width: $theme-view-widht;
        min-width: $theme-view-min-widht;
        margin: 0 auto;
        padding-bottom: 100px;
      }

      .vip-palce {
        .vp-title {
          box-sizing: border-box;
          padding: 50px 0 20px;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 36px;
          color: #444343;

          .title-text {
            box-sizing: border-box;
            padding: 0 37px;
          }

          &::after,
          &::before {
            content: '';
            display: block;
            height: 1px;
            background: #242424;
            flex: 1;
            min-width: 0;
          }
        }

        .vp-rule {
          margin-bottom: 107px;
          text-align: center;

          .rule-text {
            font-size: 20px;
            color: #D42A20;
            cursor: pointer;

            .iconfont {
              position: relative;
              top: -2px;
              font-size: 12px;
            }
          }
        }
      }

      .vip-list {
        display: flex;
        // justify-content: space-around;

        .vl-item {
          box-sizing: border-box;
          position: relative;
          padding: 15px;
          padding-bottom: 100px;
          $gutter: 60px; // 间隔
          // display: flex;
          // flex-direction: column;
          margin-left: 3%;
          width: calc(100% / 3);
          min-height: 300px;
          border-radius: 10px;
          overflow: hidden;
          background: #F7F7F7;
          box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .3);

          &:first-of-type {
            margin-left: 0;
          }

          .vl-sign {
            display: inline-block;
            position: relative;
            // height: 80px;

            .fake-img {
              // width: 100%;
              height: 100%;
            }

            .vl-recommend {
              box-sizing: border-box;
              // height: 26px;
              // line-height: 26px;
              padding: 3px 12px;
              border-radius: 26px;
              background: #FFF1DD;
              z-index: 1;
              position: absolute;
              top: 0;
              right: 0;
              transform: translateX(calc(100% - 5px));
              white-space: nowrap;
              font-size: 16px;
              color: #D42A20;
            }
          }

          .vl-price {
            $raw-font-size: 16px;
            display: flex;
            color: #444343;

            .price-main {
              flex: 1;
              min-width: 0;
              text-align: center;
              font-size: 22px;

              .price-unit {
                position: relative;
                font-size: 36px;
              }

              .price-text {
                font-size: 88px;
              }

              .price-raw {
                box-sizing: border-box;
                padding-left: 12px;
                position: absolute;
                top: 50%;
                transform: translateY(calc(-50% + 5px));
                font-size: $raw-font-size;
                text-decoration: line-through;
                white-space: nowrap;
              }
            }

            .price-rest {
              min-width: 0;

              .price-placeholder {
                box-sizing: border-box;
                padding-left: 12px;
                visibility: hidden;
                font-size: $raw-font-size;
              }
            }
          }

          .vl-botton-module {
            text-align: center;

            .vbm-place {
              display: inline-block;
              width: auto;
              margin: 50px auto 0;
            }

            .vbm-line {
              width: 80%;
              border-top: 2px dashed #EBEBEB;
              margin: 20px auto 5px;
            }

            .vbm-text {
              font-size: 12px;
              color: #787878;
            }
          }

          .vl-botton {
            text-align: center;

            .fake-btn {
              // margin-top: 50px;
              padding: 0;
              width: 248px;
              height: 40px;
              font-size: 18px;
              color: #fff;
              border-radius: 100px;
              border: none;
              outline: none;
              box-shadow: 3px 4px 3px -1px rgba(0, 0, 0, 0.2);

              &.el-button:hover,
              &.el-button:focus,
              &.el-button:active {
                color: none;
                border-color: none;
                background-color: none;
              }

              .iconfont {
                margin-right: 6px;
              }
            }

            &::v-deep {
              .el-button {
                background: -webkit-linear-gradient(#FFD35F, #FFDE59);
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(#FFD35F, #FFDE59);
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(#FFD35F, #FFDE59);
                /* Firefox 3.6 - 15 */
                background: linear-gradient(#FFD35F, #FFDE59);
                /* 标准的语法（必须放在最后） */
              }
            }

            &.number1 {
              &::v-deep {
                .el-button {
                  background: -webkit-linear-gradient(#6D81FF, #C2C8FF);
                  /* Safari 5.1 - 6.0 */
                  background: -o-linear-gradient(#6D81FF, #C2C8FF);
                  /* Opera 11.1 - 12.0 */
                  background: -moz-linear-gradient(#6D81FF, #C2C8FF);
                  /* Firefox 3.6 - 15 */
                  background: linear-gradient(#6D81FF, #C2C8FF);
                  /* 标准的语法（必须放在最后） */
                }
              }
            }

            &.number2 {
              &::v-deep {
                .el-button {
                  background: -webkit-linear-gradient(#0D1D30, #454C5F);
                  /* Safari 5.1 - 6.0 */
                  background: -o-linear-gradient(#0D1D30, #454C5F);
                  /* Opera 11.1 - 12.0 */
                  background: -moz-linear-gradient(#0D1D30, #454C5F);
                  /* Firefox 3.6 - 15 */
                  background: linear-gradient(#0D1D30, #454C5F);
                  /* 标准的语法（必须放在最后） */
                }
              }
            }
          }


          .block-title {
            width: 80%;
            margin: 0 auto;
            display: flex;
            align-items: center;

            .title-line {
              flex: 1;
              min-width: 0;
              height: 1px;
              background: #787878;
            }

            .title-text {
              box-sizing: border-box;
              padding: 0 24px;
              font-size: 16px;
              color: #787878;
            }
          }

          .vl-privilege {
            margin-top: 70px;

            .vpr-list {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              // &::after {
              //   content: '';
              //   display: block;
              //   clear: both;
              // }

              .vpr-item {
                margin-top: 30px;
                width: calc(100% / 3);
                // float: left;
                text-align: center;
                color: #444343;

                &.a {
                  width: calc(100% / 4);
                }

                .icon {
                  $size: 43px;
                  width: $size;
                  height: $size;
                  line-height: $size;
                  background: #FEDD7C;
                  box-sizing: border-box;
                  padding: 7px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin: 0 auto;

                  .fake-img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .name {
                  margin: 12px 0 7px;
                  font-size: 16px;
                }

                .descriptions {
                  font-size: 12px;
                  color: #808080;
                }
              }
            }

          }

          .vl-privilege-two {
            margin-top: 70px;

            .vpt-list {
              box-sizing: border-box;
              padding: 0 24px;

              .vpt-item {
                margin-top: 30px;
                display: flex;
                align-items: center;

                .vpt-icon {
                  $size: 36px;
                  width: $size;
                  height: $size;
                  line-height: $size;
                  box-sizing: border-box;
                  padding: 7px;
                  margin-right: 8px;

                  .fake-img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .vpt-info {
                  .vpt-title {
                    box-sizing: border-box;
                    padding-right: 10px;
                    font-size: 18px;
                    color: #444343;
                    // .title-key {}
                    // .title-val {}
                  }

                  .vpt-descriptions {
                    font-size: 14px;
                    color: #787878;
                  }
                }

              }
            }
          }
        }
      }
    }
  }

</style>
